import applyPolyfills from '@benefex/react/app/applyPolyfills';

applyPolyfills()
  .then(() => import(/* webpackChunkName: "appMainStyles" */ './index.scss'))
  .then(
    () =>
      import(/* webpackChunkName: "appOHDSStyles" */ '@benefex/components/dist/design-system.css'),
  )
  .then(() => import(/* webpackChunkName: "appOHCStyles" */ '@benefex/components/dist/index.css'))
  .then(() => import(/* webpackChunkName: "appMain" */ './main'));
